<template>

  <v-card class="mx-0 pa-2" elevation="0" v-if="selected_asset !== null" outlined>
    <v-row no-gutters>
      <v-row justify="space-between">
        <v-col>
          <h3 class="blue--text text--darken-2">{{ selected_asset.name }}
            <span class="grey--text font-italic">#{{ selected_asset.id }}</span></h3>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn v-if="isResponsableOrAdmin || hasPermission('change_asset')" @click="editAsset()" text color="primary">
            <v-icon>mdi-pencil</v-icon>
            Modifier
          </v-btn>
          <v-dialog v-model="deleteDialog" v-if="isResponsableOrAdmin || hasPermission('delete_asset')" width="500">
            <template v-slot:activator="{on, attrs}">
              <v-btn text v-on="on" v-bind="attrs" color="error">
                <v-icon>mdi-trash-can-outline</v-icon>
                Supprimer
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline red lighten-1 white--text">
                Supprimer l'équipement
              </v-card-title>
              <v-card-text class="body-1 mt-2">
                Confirmer la suppression de l'équipement ?
              </v-card-text>
              <v-card-actions>
                <v-btn @click="deleteAsset(selected_asset)" text color="red">Supprimer l'équipement</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-col cols="12" class="mb-2">
        <span class="primary--text">
          {{ selected_asset.category !== null ? selected_asset.category.name : '' }}
          </span>
        <br>
      </v-col>
      <v-col cols="12" class="mb-2">
        <p>
          {{ selected_asset.description !== null ? selected_asset.description : "" }}
        </p>
        <br>
      </v-col>
      <v-col cols="6" xl="3">
        <v-list-item :three-line="selected_asset.site != null" :two-line="selected_asset.site == null">
          <v-list-item-icon class="align-center justify-center">
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Site
            </v-list-item-title>
            <v-list-item-subtitle v-if="selected_asset.site !== null">
              <span v-if="selected_asset !== null">{{ selected_asset.site.name }}</span>
              <span v-if="selected_asset.site.parent !== null">, {{ selected_asset.site.parent }}</span>
              <span v-if="selected_asset.site.top !== null">, {{ selected_asset.site.top }}</span>
              <span v-if="selected_asset === null" class="font-italic grey--text">Non défini</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="pa-0" v-else>
              <span class="font-italic">Pas de site associé<br></span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.balise !== null">
        <detail-card @click.native="barcodeDialog = true" clickable name="Balise" icon="mdi-barcode-scan"
                     :information="selected_asset.balise"></detail-card>
        <v-dialog
            width="500"
            v-model="barcodeDialog"
        >
          <v-card>
            <v-card-title class="headline primary white--text">
              Code barre
            </v-card-title>
            <v-row v-if="selected_asset.code_barre !== ''" align="center" justify="center">
              <v-img id="loaded-barcode" :src="pieceJointeLink(selected_asset.code_barre)"></v-img>
            </v-row>
            <v-card-text class="pt-2" v-else>
              Le code barre n'a pas encore été généré pour cette équipement.
              Veuillez ré-enregistrer l'équipement pour le regénérer, et si le problème persiste, veuillez contacter un
              administrateur.
            </v-card-text>
            <v-card-actions>
              <v-btn @click="printBarcode(pieceJointeLink(selected_asset.code_barre))" text color="primary">Imprimer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col cols="6" xl="3" v-if="selected_asset.balise !== null">
        <detail-card @click.native="datamatrixDialog = true" clickable name="Datamatrix" icon="mdi-qrcode"
                     :information="selected_asset.balise"></detail-card>
        <v-dialog
            width="500"
            v-model="datamatrixDialog"
        >
          <v-card>
            <v-card-title class="headline primary white--text">
              Datamatrix
            </v-card-title>
            <v-row v-if="selected_asset.code_barre !== ''" align="center" justify="center">
              <v-img id="loaded-barcode" :src="selected_asset.data_matrix"></v-img>
            </v-row>
            <v-card-text class="pt-2" v-else>
              Le code Datamatrix n'a pas encore été généré pour cette équipement.
              Veuillez ré-enregistrer l'équipement pour le regénérer, et si le problème persiste, veuillez contacter un
              administrateur.
            </v-card-text>
            <v-card-actions>
              <v-btn @click="printBarcode(selected_asset.data_matrix)" text color="primary">Imprimer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col cols="6" xl="3" v-if="selected_asset.reference !== null">
        <detail-card icon="mdi-information-outline" name="Référence"
                     :information="selected_asset.reference !== null ? selected_asset.reference : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.impact !== null">
        <detail-card icon="mdi-exclamation" name="Impact"
                     :information="selected_asset.impact !== null ? impact_text[selected_asset.impact] : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.etat !== null">
        <detail-card icon="mdi-image-broken-variant" name="État"
                     :information="selected_asset.etat !== null ? state_text[selected_asset.etat] : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.type !== null">
        <detail-card icon="mdi-briefcase" name="Type"
                     :information="selected_asset.type !== null ? type_text[selected_asset.type] : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.numero_serie !== null">
        <detail-card icon="mdi-barcode" name="Numéro de série"
                     :information="selected_asset.numero_serie !== null ? selected_asset.numero_serie : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.marque !== null">
        <detail-card icon="mdi-copyright" name="Marque"
                     :information="selected_asset.marque !== null ? selected_asset.marque : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.intervenant !== null">
        <detail-card icon="mdi-account-tie" name="Intervenant"
                     :information="selected_asset.intervenant !== null ? selected_asset.intervenant.fullname : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.cout_achat !== null">
        <detail-card icon="mdi-currency-eur" name="Coût d'achat"
                     :information="selected_asset.cout_achat !== null ? selected_asset.cout_achat + ' €' : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.amortissement !== null">
        <detail-card icon="mdi-currency-eur-off" name="Amortissement"
                     :information="amortissement"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.date_achat !== null">
        <detail-card icon="mdi-calendar-arrow-right" name="Date d'achat"
                     :information="selected_asset.date_achat !== null ? getFormattedDate(selected_asset.date_achat) : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.date_fin_garantie !== null">
        <detail-card icon="mdi-calendar-check" name="Date de fin de garantie"
                     :information="selected_asset.date_fin_garantie !== null ? getFormattedDate(selected_asset.date_fin_garantie) : 'Non défini'"></detail-card>
      </v-col>
      <v-col cols="6" xl="3" v-if="selected_asset.to_label !== null">
        <detail-card icon="mdi-barcode" name="Équipement à étiquetter"
                     :information="this.selected_asset.to_label ? 'Oui' : 'Non'"></detail-card>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" :to="'/tickets?new=true&asset=' + selected_asset.id" class="white--text">Créer un ticket</v-btn>
        <v-btn @click="changeLabelingStatus" color="primary" class="white--text ml-2"><v-icon>mdi-barcode</v-icon>
          <span v-if="selected_asset.to_label" >Marquer comme étiquetté</span>
          <span v-else>Marquer comme à étiquetter</span>
        </v-btn>
      </v-col>
      <v-col class="my-2" cols="12" v-if="isResponsableOrAdmin || hasPermission('view_contract')">
        <h4>Contrats</h4>
        <v-row v-if="selected_asset.contrats.length > 0">
          <v-col cols="12" md="6" xl="4" v-for="contrat in selected_asset.contrats" :key="contrat.id">
            <detail-card clickable @click.native="$router.push('/contracts/' + contrat.id)" v-if="contrat !== null"
                         icon="mdi-file-document-outline" :name="contrat.name"
                         :information="getContractType(contrat)"></detail-card>
          </v-col>
        </v-row>
        <div v-else class="font-italic">Pas de contrat associé à cet équipement.</div>
      </v-col>


      <v-col cols="12">
        <h4>Interventions</h4>
        <v-row v-if="loadingInterventions" justify="center">
          <v-spacer></v-spacer>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <v-spacer></v-spacer>
        </v-row>
        <v-data-iterator v-else :items="affected_tickets" item-key="id" :items-per-page="4">
          <template v-slot:default="{items}">
            <v-row>
              <v-col cols="6" xl="3" v-for="ticket in items" :key="ticket.id">
                <detail-card clickable @click.native="$router.push('/interventions/' + ticket.intervention.id)"
                             :name="'Demande #' + ticket.id" icon="mdi-hammer-wrench"
                :information="ticket.objet"></detail-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="10">
        <p class="body-1"><strong>{{ selected_asset.attachments.length }} pièce<span
            v-if="selected_asset.attachments.length > 1">s</span> jointe<span
            v-if="selected_asset.attachments.length > 1">s</span> </strong></p>
      </v-col>
      <v-col cols="2">
        <v-dialog v-model="attachmentDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" elevation="2" color="primary" fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <PieceJointeForm @close-dialog="attachmentDialog = false;" type="asset"/>
        </v-dialog>
      </v-col>
      <v-col cols="6" xl="3" class="mb-2 pa-0 mini-card" v-for="attachment in selected_asset.attachments"
             :key="attachment.id">
        <v-row>
          <v-col cols="10" class="pa-0">
            <v-list-item two-line :href="attachment.file" target="_blank">
              <v-list-item-icon v-if="hasThumbnail(attachment)">
                <v-img max-width="50" :src="attachment.thumbnail"></v-img>
              </v-list-item-icon>
              <v-list-item-icon v-else>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ attachment.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getFilename(attachment.path) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col class="align-center justify-center pa-0" cols="2">
            <v-icon @click="deleteAttachment(attachment)">mdi-trash-can-outline</v-icon>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <v-row>
      <v-divider></v-divider>
    </v-row>
    <v-row no-gutters class="py-4 ml-3">
      <v-col>
        <!--<v-btn @click="changeState('finished')"
               v-if="selected_ticket.state !== 'finished' && selected_ticket.state !== 'closed'"
               class="green darken-3" light>
          <span class="white--text"><v-icon left>mdi-check</v-icon>Passer en "réalisé"</span>
        </v-btn>-->
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import config from "@/config";
import DetailCard from "@/components/base/DetailCard";
import dayjs from "dayjs";
import PieceJointeForm from "@/components/tickets/PieceJointeForm";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {ContractMixin} from "@/mixins/contracts/ContractMixin";
import PieceJointeMixin from "@/mixins/util/PieceJointeMixin";

export default {
  name: 'AssetView',
  components: {DetailCard, PieceJointeForm},
  mixins: [DateUtilMixin, AuthMixin, ContractMixin, PieceJointeMixin],
  data: function () {
    return {
      barcodeDialog: false,
      datamatrixDialog: false,
      attachmentDialog: false,
      deleteDialog: false,
      loadingInterventions: false,
      assetLoaded: false,
      toLabel: false,
    };
  },
  created() {
    this.impact_text = {
      "low": "Bas",
      "medium": "Moyen",
      "high": "Haut"
    };
    this.type_text = {
      "bati": "Bâti",
      "technique": "Technique"
    };
    this.state_text = {
      'new': "Nouveau",
      'used': 'Utilisé',
      'bad': "Mauvais",
    };
    this.dayjs = dayjs;
  },
  async mounted() {
    let assetId = this.$route.params?.id;
    if (assetId !== undefined) {
      await this.$store.dispatch("assets/fetchAssetById", assetId);
      this.assetLoaded = true;
      this.loadingInterventions = true;
      await this.$store.dispatch("tickets/fetchTicketsByAsset", {id: assetId});
      this.loadingInterventions = false;
    }
  },
  computed: {
    selected_asset() {
      return this.$store.getters["assets/getSelectedAsset"];
    },
    affected_tickets() {
      return this.$store.getters["tickets/affected_tickets"];
    },
    amortissement() {
      if (this.selected_asset.amortissement !== null) {
        if (this.selected_asset.amortissement === 0) {
          return "Non amortissable";
        } else if (this.selected_asset.amortissement === 1) {
          return "1 an";
        } else {
          return this.selected_asset.amortissement + " ans";
        }
      } else {
        return 'Non défini';
      }
    }
  },
  methods: {
    editAsset() {
      this.$emit("edit-asset")
    },
    async reloadAsset(assetId) {
      await this.$store.dispatch("assets/fetchAssetById", assetId);
      this.assetLoaded = true;
      this.loadingInterventions = true;
      await this.$store.dispatch("tickets/fetchTicketsByAsset", {id: assetId});
      this.loadingInterventions = false;
    },
    pieceJointeLink(file) {
      return config.BASE_URL + file;
    },
    async deleteAsset(asset) {
      await this.$store.dispatch("assets/deleteAsset", asset);
      this.$store.dispatch("messages/announceSuccess", "Équipement supprimé.");
      this.deleteDialog = false;
    },
    async deleteAttachment(attachment) {
      let result = await this.$store.dispatch("attachments/deleteAssetAttachment", attachment);
      if (result === "success") {
        this.$store.dispatch("messages/setAnnounce", {"type": "success", "message": "Document supprimé."});
      } else {
        this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Une erreur est survenue. Le document n'a peut être pas été correctement supprimé."
        });
      }
    },
    async changeLabelingStatus() {
      await this.$store.dispatch("assets/putAsset", {id: this.selected_asset.id, to_label: !this.selected_asset.to_label});
      await this.reloadAsset(this.selected_asset.id);
      this.$store.dispatch("messages/announceSuccess", "Opération effectué.");
    },
    getFilename(path) {
      let elements = path.split("/")
      return elements[elements.length - 1]
    },
    printBarcode(src) {
      var Pagelink = "about:blank";
      let pageHtml = "<html><head><scri" + "pt>function step1(){\n" +
          "setTimeout('step2()', 10);}\n" +
          "function step2(){window.print();window.close()}\n" +
          "</scri" + "pt></head><body onload='step1()'>\n" +
          "<img style='max-width: 50vw' src='" + src + "' /></body></html>";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(pageHtml);
      pwa.document.close();
    },

  }
}
</script>